import { EBIZIO_CACHE_LIFETIME_IN_MINUTES } from '../constants';

export interface CacheEntry<Value> {
  data: Value;
  expiry: number;
}

export type CacheKey = 'ebizio-module-config' | 'ebizio-module-access';

export const addMinutes = (date: Date, minutes: number) => {
  return date.getTime() + minutes * 60000;
};

export const setCacheItem = <Value>(
  key: CacheKey,
  value: Value,
  expiryInMinutes = EBIZIO_CACHE_LIFETIME_IN_MINUTES
) => {
  const now = new Date();

  const item: CacheEntry<Value> = {
    data: value,
    expiry: addMinutes(now, expiryInMinutes),
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getCacheItem = <Value>(key: CacheKey) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const now = new Date();
  const item: CacheEntry<Value> = JSON.parse(itemStr);

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);

    return null;
  }

  return item;
};
