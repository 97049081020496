import Axios from 'axios';

import { Cart } from './interfaces';
import {
  applyProductFees,
  clearDestinationBasedProductFees,
  clearProductFees,
  getModuleAccess,
  getModuleConfig,
  ModuleAccess,
  ModuleConfig,
} from './api';
import { dispatchEbizioEvent, EBIZIO_EVENTS, logger } from './utils';

/**
 * interfaces
 */
interface Config {
  version: string;
  storeHash: string;
  pageType: string;
}

interface State {
  access: ModuleAccess;
  config: ModuleConfig;
  cart: Cart | null;
  appliedListeners: boolean;
}

/**
 * primary class
 */
class EbizioStorefrontScript {
  config: Config;
  state: State;
  // loader: Loader;

  constructor(config: Partial<Config>) {
    // STATIC: things that are set once the class is initialized
    this.config = {
      version: config.version || '',
      storeHash: config.storeHash || '',
      pageType: config.pageType || '',
    };

    logger(`Storefront Script (${this.config.version})`);

    // DYNAMIC: things that change based on logic or events
    this.state = {
      access: {
        customerGroupPaymentRestrictions: false,
        customerGroupShippingRestrictions: false,
        destinationBasedProductFees: false,
        eventManager: false,
        productFees: false,
        purchaseOrder: false,
        shipOnAccount: false,
        styleEditor: false,
        textEditor: false,
      },
      config: {
        productFees: {
          behavior: 'NONE',
        },
      },
      cart: null,
      appliedListeners: false,
    };

    if (this.config.pageType === 'checkout') {
      return;
    }

    this.init();
  }

  /**
   * method to update our state
   */
  setState(updates: Partial<State>): void {
    const newState: State = {
      ...this.state,
      ...updates,
    };

    this.state = newState;
  }

  /**
   * get module access
   */
  async updateModuleAccessState(): Promise<void> {
    const { data, isError } = await getModuleAccess(this.config.storeHash);

    if (!isError && data) {
      this.setState({ access: data });
    }
  }

  /**
   * get module config
   */
  async updateModuleConfigState(): Promise<void> {
    // only product fees has config so only request it if we have access to that module
    if (!this.state.access.productFees) {
      return;
    }

    const { data, isError } = await getModuleConfig(this.config.storeHash);

    if (!isError && data) {
      this.setState({ config: data });
    }
  }

  /**
   * get cart state
   */
  async updateCartState(): Promise<void> {
    const needForDBPF =
      this.state.access.destinationBasedProductFees &&
      this.config.pageType === 'cart';
    const needForPF =
      this.state.access.productFees &&
      this.state.config.productFees.behavior !== 'NONE';
    if (!needForDBPF && !needForPF) {
      return;
    }

    logger(`-- fetching BigCommerce cart`);

    try {
      const { data } = await Axios.get(
        '/api/storefront/carts?include=lineItems.digitalItems.options%2ClineItems.physicalItems.options'
      );

      this.setState({ cart: data[0] ?? null });
    } catch (error: any) {
      logger(`unable to fetch BigCommerce cart: ${error.message}`);

      this.setState({ cart: null });
    }
  }

  /**
   * apply product fees to the cart
   */
  async runApplyProductFees(shouldReloadPage = false): Promise<void> {
    if (!this.state.access.productFees || !this.state.cart) {
      return;
    }

    const { isError } = await applyProductFees(
      this.state.cart.id,
      this.config.storeHash
    );

    if (!isError) {
      dispatchEbizioEvent(EBIZIO_EVENTS.EBIZIO_APPLIED_RATES, {
        cartId: this.state.cart.id,
      });
    }

    if (shouldReloadPage) {
      window.location.reload();
    }
  }

  /**
   * util for checking if we have destination based product fees in the cart
   */
  haveProductFeesInCart(): boolean {
    if (!this.state.cart) {
      return false;
    }

    const lineItems = this.state.cart.lineItems;
    if (!lineItems) {
      return false;
    }

    const physicalFees = lineItems.physicalItems.filter(
      ({ options }) =>
        options &&
        options.filter((opt) => opt.name.match(/Ebizio Fee ID/)).length > 0
    );

    const digitalFees = lineItems.digitalItems.filter(
      ({ options }) =>
        options &&
        options.filter((opt) => opt.name.match(/Ebizio Fee ID/)).length > 0
    );

    return physicalFees.length !== 0 || digitalFees.length !== 0;
  }

  /**
   * clear destination based product fees
   */
  async runClearProductFees(): Promise<void> {
    if (!this.state.access.productFees) {
      return;
    }

    const haveFeesInCart = this.haveProductFeesInCart();
    if (!this.state.cart || !haveFeesInCart) {
      return;
    }

    const { isError } = await clearProductFees(
      this.state.cart.id,
      this.config.storeHash
    );

    if (!isError) {
      window.location.reload();
    }
  }

  /**
   * util for checking if we have destination based product fees in the cart
   */
  haveDestinationBasedFeesInCart(): boolean {
    if (!this.state.cart) {
      return false;
    }

    const lineItems = this.state.cart.lineItems;
    if (!lineItems) {
      return false;
    }

    const fees = lineItems.giftCertificates.filter(
      ({ theme }) => theme === 'ebizio-dbf'
    );

    return fees.length !== 0;
  }

  /**
   * clear destination based product fees
   */
  async runClearDestinationBasedProductFees(): Promise<void> {
    if (!this.state.access.destinationBasedProductFees) {
      return;
    }

    const haveFeesInCart = this.haveDestinationBasedFeesInCart();
    if (!this.state.cart || !haveFeesInCart) {
      return;
    }

    const { isError } = await clearDestinationBasedProductFees(
      this.state.cart.id,
      this.config.storeHash
    );

    if (!isError) {
      window.location.reload();
    }
  }

  /**
   * listen for events
   */
  applyListeners(): void {
    if (this.state.appliedListeners) {
      return;
    }

    this.setState({ appliedListeners: true });

    if (!window.stencilUtils || !window.stencilUtils.hooks) {
      return;
    }

    const isCartPage = this.config.pageType === 'cart';

    window.stencilUtils.hooks.on('cart-item-add-remote', async () => {
      logger('Item Added to Cart');
      if (this.state.config.productFees.behavior === 'ACTIVE') {
        await this.runApplyProductFees(isCartPage);
      }
    });

    window.stencilUtils.hooks.on('cart-item-update-remote', async () => {
      logger('Cart Item Updated');
      if (this.state.config.productFees.behavior === 'ACTIVE') {
        await this.runApplyProductFees(isCartPage);
      }
    });

    window.stencilUtils.hooks.on('cart-item-remove-remote', async () => {
      logger('Item Removed From Cart');
      if (this.state.config.productFees.behavior === 'ACTIVE') {
        await this.runApplyProductFees(isCartPage);
      }
    });
  }

  /**
   * initialize stuffz
   */
  async init(): Promise<void> {
    // 1. get module access
    await this.updateModuleAccessState();
    // 2. get module config
    await this.updateModuleConfigState();
    // 3. update cart state by fetching the current cart
    await this.updateCartState();
    // 4. if cart page, remove destination based product fees
    if (this.config.pageType === 'cart') {
      await this.runClearDestinationBasedProductFees();
      if (this.state.config.productFees.behavior === 'CLEAR') {
        // clear product fees if we have fees in the cart (SKUs starting with "EBIZIO-FEE-")
        await this.runClearProductFees();
      }
    }
    // 5. re apply product fee rates
    if (this.state.config.productFees.behavior === 'ACTIVE') {
      await this.runApplyProductFees();
    }
    // 6. apply listeners
    this.applyListeners();
  }
}

/**
 * expose our class to the window object
 */
declare global {
  interface Window {
    EbizioStorefrontScript: {};
    stencilUtils: {
      hooks: {
        on(
          eventName: string,
          callback: (event: Event, target: HTMLElement) => void
        ): void;
      };
    };
  }
}

window.EbizioStorefrontScript = EbizioStorefrontScript;

export default EbizioStorefrontScript;
