import Axios from 'axios';

import { getCacheItem, logger, setCacheItem } from '../utils';

export interface ModuleAccess {
  customerGroupPaymentRestrictions: boolean;
  customerGroupShippingRestrictions: boolean;
  destinationBasedProductFees: boolean;
  eventManager: boolean;
  productFees: boolean;
  purchaseOrder: boolean;
  shipOnAccount: boolean;
  styleEditor: boolean;
  textEditor: boolean;
}

export interface ModuleAccessResponse {
  meta: {
    status: 'success' | 'error';
    message?: string;
  };
  data: {
    access: ModuleAccess;
  };
}

export interface GetModuleAccessResponse {
  data: ModuleAccess | null;
  message: string;
  isError: boolean;
}

export type GetModuleAccess = (
  storeHash: string
) => Promise<GetModuleAccessResponse>;

// get module access
export const getModuleAccess: GetModuleAccess = async (storeHash) => {
  // try to pull from cache first
  const cachedResponse = getCacheItem<ModuleAccess>('ebizio-module-access');
  if (cachedResponse) {
    logger(
      `-- using cached Module Access (expires ${new Date(
        cachedResponse.expiry
      ).toLocaleTimeString()})`
    );

    return {
      data: cachedResponse.data,
      message: 'Success',
      isError: false,
    };
  }

  logger('-- fetching Module Access');

  try {
    const { data, meta }: ModuleAccessResponse = await Axios.get(
      `${process.env.COOPC_BASE_URL}app/module_access?store_hash=${storeHash}`
    ).then((res) => res.data);
    if (!data) {
      return {
        data: null,
        message: `Error: Something went wrong`,
        isError: true,
      };
    }
    // handle response
    if (meta && meta.status === 'error') {
      return {
        data: null,
        message: `Error: ${meta.message || ''}`,
        isError: true,
      };
    }

    setCacheItem<ModuleAccess>('ebizio-module-access', data.access);

    return {
      data: data.access,
      message: meta.message || 'Success',
      isError: false,
    };
  } catch (error: any) {
    logger(`unable to get Module Access: ${error.message}`);
    const errMessage = error?.response?.data.meta.message;

    return {
      data: null,
      message: `Error: ${errMessage || error.message}`,
      isError: true,
    };
  }
};
