import { EBIZIO_CONTAINER, EBIZIO_CONTAINER_ID } from '../constants';
import { logger } from './logger';

export enum EBIZIO_EVENTS {
  EBIZIO_APPLIED_RATES = 'EBIZIO_APPLIED_RATES',
  EBIZIO_APPLIED_PRODUCT_FEES = 'EBIZIO_APPLIED_PRODUCT_FEES',
}

export const dispatchEbizioEvent = (eventName: EBIZIO_EVENTS, data: any) => {
  if (!EBIZIO_CONTAINER) {
    logger(
      `unable to emit event "${eventName}" because element with ID "${EBIZIO_CONTAINER_ID}" not found`,
    );
    return;
  }

  const event = new CustomEvent(eventName, {
    detail: data,
    bubbles: true,
    cancelable: true,
    composed: false,
  });

  EBIZIO_CONTAINER.dispatchEvent(event);
};
