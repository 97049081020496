import Axios from 'axios';

import { Cart } from '../interfaces';
import { logger } from '../utils/logger';

export enum ProductFeeRateType {
  NONE = 'none',
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
  PERCENTAGE_OF_PRODUCT = 'percentage_of_product',
}

export enum ProductFeeToggleLocation {
  TOP_CUSTOMER = 'top_customer',
  TOP_SHIPPING = 'top_shipping',
  TOP_BILLING = 'top_billing',
  TOP_PAYMENT = 'top_payment',
}

export interface ProductFeeRateRuleToggleSettings {
  id: string;
  rule: string;
  apply_by_default: boolean;
  title_toggled: string;
  title_untoggled: string;
  body: string;
  image: string;
  location: ProductFeeToggleLocation;
}

export interface ProductFeeRateRule {
  id: string;
  store: string;
  product_group_id: string;
  is_enabled: boolean;
  portion_taxable: string;
  name: string;
  frequency: 'per_sku' | 'per_product' | 'per_order';
  is_taxed: boolean;
  is_toggleable: boolean;
  toggle_settings: ProductFeeRateRuleToggleSettings;
}

export interface ProductFeeRate {
  type: ProductFeeRateType;
  rate: string;
  rule: ProductFeeRateRule;
  applicable_items: ProductFeeApplicableItem[];
}

export interface ProductFeeApplicableItem {
  id: string;
  variant_id: number;
  product_id: number;
  sku: string;
  name: string;
  quantity: number;
  price: number;
}

export interface ProductFee {
  value: number;
  name: string;
  applicable_items: ProductFeeApplicableItem[];
  source_rate: ProductFeeRate;
  type: 'individual' | 'combined';
  toggled: boolean;
  identifier: string;
}

export interface ProductFeesResponse {
  meta: {
    status: 'success' | 'error';
    message: string;
  };
  data: {
    fees: ProductFee[];
  };
}

export interface ApplyProductFeesResponseDataToggle {
  identifier: string;
  toggled: boolean;
  title_toggled: string;
  title_untoggled: string;
  body: string;
  image: string;
  location: ProductFeeToggleLocation;
}

export interface ApplyProductFeesResponseData {
  cartId: string;
  storeHash: string;
  toggles: ApplyProductFeesResponseDataToggle[];
}

export interface ApplyProductFeesResponse {
  data: ApplyProductFeesResponseData | null;
  message: string;
  isError: boolean;
}

export type ApplyProductFeesToggle = Record<string, boolean>;

export type ApplyProductFees = (
  cartId: Cart['id'],
  storeHash: string,
  toggles?: ApplyProductFeesToggle
) => Promise<ApplyProductFeesResponse>;

// helpers for returning a nicer response
const isToggleable = (fee: ProductFee): boolean =>
  fee.source_rate.rule.is_toggleable;

const toResponseDataToggle = (
  fee: ProductFee
): ApplyProductFeesResponseDataToggle => ({
  identifier: fee.identifier,
  toggled: fee.toggled,
  title_toggled: fee.source_rate.rule.toggle_settings.title_toggled,
  title_untoggled: fee.source_rate.rule.toggle_settings.title_untoggled,
  body: fee.source_rate.rule.toggle_settings.body,
  image: fee.source_rate.rule.toggle_settings.image,
  location: fee.source_rate.rule.toggle_settings.location,
});

// actual function to apply or update fees
export const applyProductFees: ApplyProductFees = async (
  cartId,
  storeHash,
  toggles = {}
) => {
  logger('-- applying Product Fees to cart');

  const postBody = {
    store: storeHash,
    cart_id: cartId,
    toggles,
  };

  try {
    const { data, meta }: ProductFeesResponse = await Axios.post(
      `${process.env.COOPC_BASE_URL}app/product_fee/apply?store_hash=${storeHash}`,
      postBody
    ).then((res) => res.data);
    if (!data) {
      return {
        data: null,
        message: `Error: Something went wrong`,
        isError: true,
      };
    }
    // handle response
    if (meta && meta.status === 'error') {
      return {
        data: null,
        message: `Error: ${meta.message || ''}`,
        isError: true,
      };
    } else {
      // things are good, reshape data to make easier to use
      const formattedData: ApplyProductFeesResponseData = {
        cartId,
        storeHash,
        toggles: data.fees.filter(isToggleable).map(toResponseDataToggle),
      };

      return {
        data: formattedData,
        message: meta.message || 'Success',
        isError: false,
      };
    }
  } catch (error: any) {
    logger(`unable to apply Product Fees: ${error.message}`);
    const errMessage = error?.response?.data.meta.message;

    return {
      data: null,
      message: `Error: ${errMessage || error.message}`,
      isError: true,
    };
  }
};
