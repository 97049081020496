import Axios from 'axios';

import { Cart } from '../interfaces';
import { logger } from '../utils/logger';

export interface ClearProductFeesResponse {
  data: null;
  message: string;
  isError: boolean;
}

export type ClearProductFees = (
  cartId: Cart['id'],
  storeHash: string
) => Promise<ClearProductFeesResponse>;

// actual function to clear product fees
export const clearProductFees: ClearProductFees = async (cartId, storeHash) => {
  logger('-- clearing Product Fees from cart');

  const postBody = {
    store: storeHash,
    cart_id: cartId,
  };

  try {
    const { data } = await Axios.post(
      `${process.env.COOPC_BASE_URL}app/product_fee/clear`,
      postBody
    );
    if (!data || data.meta.status !== 'success') {
      return {
        data: null,
        message: `Error: Something went wrong`,
        isError: true,
      };
    }

    return {
      data: null,
      message: 'Success',
      isError: false,
    };
  } catch (error: any) {
    logger(`unable to clear Product Fees: ${error.message}`);
    const errMessage = error?.response?.data.meta.message;

    return {
      data: null,
      message: `Error: ${errMessage || error.message}`,
      isError: true,
    };
  }
};
