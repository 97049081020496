import Axios from 'axios';

import { Cart } from '../interfaces';
import { logger } from '../utils/logger';

export interface ClearDesinationBasedProductFeesResponse {
  data: null;
  message: string;
  isError: boolean;
}

export type ClearDesinationBasedProductFees = (
  cartId: Cart['id'],
  storeHash: string
) => Promise<ClearDesinationBasedProductFeesResponse>;

// actual function to clear destination based product fees
export const clearDestinationBasedProductFees: ClearDesinationBasedProductFees =
  async (cartId, storeHash) => {
    logger('-- clearing Destination Based Product Fees from cart');

    const postBody = {
      store: storeHash,
      cart_id: cartId,
    };

    try {
      const { data } = await Axios.post(
        `${process.env.COOPC_BASE_URL}app/destination-based-product-fee/clear`,
        postBody
      );
      if (!data || data.meta.status !== 'success') {
        return {
          data: null,
          message: `Error: Something went wrong`,
          isError: true,
        };
      }

      return {
        data: null,
        message: 'Success',
        isError: false,
      };
    } catch (error: any) {
      logger(
        `unable to clear Desinstation Based Product Fees: ${error.message}`
      );
      const errMessage = error?.response?.data.meta.message;

      return {
        data: null,
        message: `Error: ${errMessage || error.message}`,
        isError: true,
      };
    }
  };
